// ensure right font is loaded
@font-face {
	font-family: 'Roboto';
	src: url('../o/Roboto-Regular.woff') format('woff'), url('../o/Roboto-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: 'Roboto';
	src: url('../o/Roboto-Bold.woff') format('woff'), url('../o/Roboto-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
}

.notification-overlay-for-different-product {
	align-items: center;
	background-color: rgba(0, 0, 0, .8);
	bottom: 0;
	display: none;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9000;

	@media only screen and (max-width: 768px) {
		overflow-y: scroll;
	}

	&.show-popup {
		display: flex;

		@media only screen and (max-width: 768px) {
			display: block;
		}
	}

	.popup-logo {
		position: absolute;
		top: 24px;
		left: 24px;
		height: 35px;
		width: auto;
	}

	.close-popup {
		background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"%3E%3Cpath d="M13 13L1 1m12 0L1 13" stroke="%234D5566" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/%3E%3C/svg%3E');
		background-size: 12px;
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		cursor: pointer;
		display: block;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		position: absolute;
		right: 24px;
		top: 24px;
		border: 1px solid #c7cdd7;
		border-radius: 100%;
		transition: background-color 0.2s;
		&:hover {
			background-color: #c7cdd7;
		}
		&:focus {
			outline: none;
		}
	}

	.content {
		align-items: center;
		background-color: #ffffff;
		color: #000000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 550px;
		padding: 104px 24px 48px;
		position: relative;
		@media only screen and (min-width: 768px) {
			width: 430px;
		}
		.main-title,
		.main-subtitle {
			font-family: 'Roboto';
            font-weight: normal;
		}
		.main-title {
			display: block;
			max-width: 333px;
			margin: 15px auto 0;
			line-height: 1.5;
			font-size: 24px;
			font-weight: 700;
		}
		.main-subtitle {
			color: #4d5566;
		}
		.main-subtitle--last {
			display: inline-block;
			margin-top: 15px;
		}
	}

	.title {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 40px;
		text-align: center;

		@media only screen and (max-width: 768px) {
			max-width: 190px;
		}
	}

	.main {
		display: block;
		font-weight: 700;
		font-size: 20px;
		padding-bottom: 5px;
	}




	.back,
	.alt-download {
		position: relative;
		display: inline-block;
		margin-top: 10px;
		font-family: 'Roboto';
		font-size: 13px;
		padding: 5px 2px;
		color: #1e222a;
		text-decoration: none;
		&:hover {
			color: #3FA4FF;
		}
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			border-bottom: 1px dashed currentColor;
		}
	}

	.text-muted {
		color: #4d5566;
	}

	.custom-font {
		font-weight: bold;
	}
}
